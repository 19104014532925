const locale = localStorage.getItem('i18nextLng');

export const dateTimeToDateTimeStringFormat:Function = (date: Date,isUpperCase=true) => {
    let dateString;
    if(isUpperCase){
        dateString = date.toLocaleString(locale === 'ar' ? 'ar-EG' : 'en-US', { day: '2-digit' }) + " " +
        date.toLocaleString(locale === 'ar' ? 'ar-EG' : 'en-US', { month: 'short' }).toUpperCase() + " " +
        date.toLocaleString(locale === 'ar' ? 'ar-EG' : 'en-US', { year: '2-digit' }) + ", " +
        date.toLocaleString(locale === 'ar' ? 'ar-EG' : 'en-US', { hour: '2-digit',hourCycle: "h23" }as any) + ":" +
        date.toLocaleString(locale === 'ar' ? 'ar-EG' : 'en-US', { minute: '2-digit' }).padStart(2, locale === 'ar' ? '٠' : "0")
    }
    else{
        dateString = date.toLocaleString(locale === 'ar' ? 'ar-EG' : 'en-US', { day: '2-digit' }) + " " +
        date.toLocaleString(locale === 'ar' ? 'ar-EG' : 'en-US', { month: 'short' })+ " " +
        date.toLocaleString(locale === 'ar' ? 'ar-EG' : 'en-US', { year: '2-digit' }) + ", " +
        date.toLocaleString(locale === 'ar' ? 'ar-EG' : 'en-US', { hour: '2-digit',hourCycle: "h23" }as any) + ":" +
        date.toLocaleString(locale === 'ar' ? 'ar-EG' : 'en-US', { minute: '2-digit' }).padStart(2, locale === 'ar' ? '٠' : "0")
    }

    return dateString
}

export const dateToDateStringFormat = (date:Date,uppercase=true)=>{
    let dateString;
    if(uppercase){
        dateString = `${date?.toLocaleString(localStorage.getItem('i18nextLng') === 'ar' ? 'ar-EG' : 'en-US', { day: '2-digit' })} 
        ${date?.toLocaleString(localStorage.getItem('i18nextLng') === 'ar' ? 'ar-EG' : 'en-US', { month: 'short' }).toUpperCase()}
        ${date?.toLocaleString(localStorage.getItem('i18nextLng') === 'ar' ? 'ar-EG' : 'en-US', { year: '2-digit' })}`
    }
    else{
        dateString = `${date?.toLocaleString(localStorage.getItem('i18nextLng') === 'ar' ? 'ar-EG' : 'en-US', { day: '2-digit' })} 
        ${date?.toLocaleString(localStorage.getItem('i18nextLng') === 'ar' ? 'ar-EG' : 'en-US', { month: 'short' })}
        ${date?.toLocaleString(localStorage.getItem('i18nextLng') === 'ar' ? 'ar-EG' : 'en-US', { year: '2-digit' })}`
    }
    return dateString
}

export const dateToLongMonthDateStringFormat = (date:Date,uppercase=true)=>{
    let dateString;
    if(uppercase){
        dateString = `${date?.toLocaleString(localStorage.getItem('i18nextLng') === 'ar' ? 'ar-EG' : 'en-US', { day: '2-digit' })} 
        ${date?.toLocaleString(localStorage.getItem('i18nextLng') === 'ar' ? 'ar-EG' : 'en-US', { month: 'long' }).toUpperCase()}
        ${date?.toLocaleString(localStorage.getItem('i18nextLng') === 'ar' ? 'ar-EG' : 'en-US', { year: '2-digit' })}`
    }
    else{
        dateString = `${date?.toLocaleString(localStorage.getItem('i18nextLng') === 'ar' ? 'ar-EG' : 'en-US', { day: '2-digit' })} 
        ${date?.toLocaleString(localStorage.getItem('i18nextLng') === 'ar' ? 'ar-EG' : 'en-US', { month: 'long' })}
        ${date?.toLocaleString(localStorage.getItem('i18nextLng') === 'ar' ? 'ar-EG' : 'en-US', { year: '2-digit' })}`
    }
    return dateString
}

export const dateToBackendDateString = (date:Date,useBackslashFormat=false)=>{
    let dateString;
    if(useBackslashFormat){
        dateString = date.toLocaleString('en-US', { day: '2-digit' })+"/"+ 
        date.toLocaleString('en-US', { month: '2-digit' }) + "/"+
        date.toLocaleString('en-US', { year: 'numeric' })
    }
    else{
        dateString = date.toLocaleString('en-US', { year: 'numeric' }) + "-" +
        date.toLocaleString('en-US', { month: '2-digit' }) + "-" +
        date.toLocaleString('en-US', { day: '2-digit' })
    }
    return dateString
}

// input : new Date()  output : 01-02-2023
export const formatDateInFrontend = (date: Date): string =>{
    return `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}`
}

export const dateTimeToDateTimeStringGraphFormat = (date: Date) => {
    let dateString = date.toLocaleString(locale === 'ar' ? 'ar-EG' : 'en-US', { hour: '2-digit',hourCycle: "h23" }as any) + ":" +
        date.toLocaleString(locale === 'ar' ? 'ar-EG' : 'en-US', { minute: '2-digit' }).padStart(2, locale === 'ar' ? '٠' : "0") + "\n" +
        date.toLocaleString(locale === 'ar' ? 'ar-EG' : 'en-US', { day: '2-digit' }) + " " +
        date.toLocaleString(locale === 'ar' ? 'ar-EG' : 'en-US', { month: 'short' })

    return dateString
}

// input 2023-11-01  output 01-11-2023 and vice verse
export const reverseDateFormat = (date:string) : string =>{
    return date.split("-").reverse().join("-");
}